<template>
  <el-dialog
    class="purchaseBox"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="900px"
    :append-to-body="true"
    :show-close="false"
    :custom-class="'purchaseWrapper'"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    @open="onOpenDialog"
    @close="handleClose"
    :destroy-on-close="true"
  >
    <div class="purchaseModalMain">
      <div class="mainHeader">
        <div class="userInfo">
          <img src="@/assets/img/avatar.svg" alt="" class="avatar" />
          <div class="userInfoRight">
            <div class="userId">ID: {{ allCert?.uid }}</div>
            <div class="user_has_count">剩余张数：{{ allCert?.has_image_count }}</div>
            <!--            <div class="tag" v-if="!isVip">普通用户</div>-->
            <!--            <div class="vipTag" v-else-if="isTimeVIP || isPermanentVIP">VIP会员</div>-->
          </div>
        </div>
        <i class="closeIcon el-icon-close" @click="handleClose"></i>
      </div>
      <div class="purchaseModalContainer">
        <div class="modalContainerLeft">
          <div class="titleBox">
            <div class="title"><span>专属权益</span></div>
            <!--            <div class="subtitle">覆盖全站所有功能</div>-->
          </div>
          <ul class="entitlementTable">
            <li v-for="(item, idx) in Rights" :key="idx">
              <span class="icon"></span>
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="modalContainerRight">
          <div class="packageListWrap">
            <div class="prevBtn" @click='handlePrev' v-if='clickNumber !== 0'></div>
            <div class='packageListContainer'>
              <div class="packageList" :style='{transform: `translateX(${ clickNumber * 150}px)`}'>
                <div
                        class="packageItem"
                        :class="{ packageItem: true, active: checkID === purchase.id }"
                        v-for="purchase in purchaseList"
                >
                  <div class="corner" v-if="purchase.corner">{{ purchase.corner }}</div>
                  <div class="packageItemContainer" @click="checkPurchase(purchase.id)">
                    <!--                <div class="corner" v-if='purchase.corner'>{{purchase.corner}}</div>-->
                    <div class="packageTitle">{{ purchase.title }}</div>
                    <div class="priceBox">
                      <div class="currentPrice">
                        ￥<span class="price">{{ purchase.price }}</span>
                      </div>
                      <div class="originPrice">{{ purchase.expired }}</div>
                    </div>
                    <div class="packageDesc">{{ purchase.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nextBtn" @click='handleNext' v-if='clickNumber !== -2'></div>
          </div>

          <div class="payInfoBox">
            <div class="qrCodeBox">
              <img :src="qrCodeSrc" alt="" class="qrCodeImg" v-if="!loading" />
              <div class="loading" v-else>
                <img src="@/assets/img/loading.png" alt="" class="loadingImg" />
              </div>
            </div>
            <div class="payInfo">
              <div class="info">
                <span>应付金额：</span>
                <span class="price" v-if="price > 0">￥{{ price }}</span>
                <!--                <div class="discounts" v-if="discountPrice > 0">-->
                <!--                  已优惠￥{{discountPrice}}元-->
                <!--                </div>-->
                <div class="payInfoTips">购买后可联系客服开具发票</div>
              </div>
              <div class="channelTips">
                <span class="wxPay"></span>
                <span class="aliPay"></span>
                <span>请使用微信/支付宝扫码支付</span>
              </div>
              <div class="payUserInfo">
                <ul class="payUserList" style="height: 72px">
                  <li>用户139****7468 12分钟前购买了 300张</li>
                  <li>用户136****8012 10分钟前购买了 1000张</li>
                  <li>用户156****9735 12分钟前购买了 300张</li>
                  <li>用户186****9320 8分钟前购买了 5000张</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tipsBox">
            <div class="tipsTitle">购买须知</div>
            <!--            <p class="tipsItem">按张付费购买下载张数 <span class="blue">永久有效，无时间限制</span></p>-->
            <p class="tipsItem">每成功下载1张图，扣除1张下载张数</p>
            <p class="tipsItem">支持反复调整抠图效果，不发生下载则不扣除张数，一张图片多次下载仅扣1张</p>
            <p class="tipsItem">需要购买更多张数？可联系客服</p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import QRCode from 'qrcode'
import { createOrder, getPayStatus } from '@/api'
import { Message } from 'element-ui'
import { Rights, DefaultPurchase } from './config'
import { trackOrder } from '@/api/track'

export default {
  name: 'PurchaseModal',
  components: {},
  data() {
    return {
      Rights,
      DefaultPurchase,
      visible: false,
      loading: false,
      checkIndex: 0,
      purchaseList: [],
      qrCodeSrc: '',
      pollCount: 0, // 轮询次数
      payResultTimer: null,
      checkID: 0,
      price: 0,
      discountPrice: 0,
      clickNumber: 0
    }
  },
  computed: {
    ...mapGetters({
      hasCount: 'userStore/hasCount'
    }),

    allCert() {
      return this.$store.state.userStore.allCert
    }
  },
  methods: {
    // 获取商品信息
    async getPurchase() {
      this.purchaseList = this.DefaultPurchase
      let currentPackage = this.purchaseList.find(item => item.defaultActive)
      await this.checkPurchase(currentPackage.id)
    },
    /**
     * @description 转换二维码
     * @param path 路径信息
     * @returns {Promise<unknown>}
     */
    async toQRCode(path) {
      return QRCode.toDataURL(path)
    },
    /**
     * @description 轮询，获取支付状态
     * @param orderId 订单ID
     */
    lookup(orderId) {
      clearTimeout(this.payResultTimer)
      if (this.pollCount > 5000) {
        // 支付超时
        this.hidePurchaseModal()
        return
      }
      this.payResultTimer = setTimeout(async () => {
        let r = await getPayStatus(orderId)
        if (r.data.status === 0 && r.data.data.order.status === 1) {
          // 查询成功 并且 状态为1 或者支付超时
          // 查询用户权益
          await this.$store.dispatch('userStore/updateAllCert')
          let price_360 = Math.round(this.price * 100)
          trackOrder(orderId, price_360).catch(e => {})
          Message({
            type: 'success',
            message: '支付成功'
          })
          clearTimeout(this.payResultTimer)
          this.hidePurchaseModal()
        } else {
          this.lookup(orderId)
        }
      }, 1500)

      this.pollCount++ // 增加轮询次数
    },
    /**
     * @description 切换套餐
     * @param id 套餐ID
     * @returns {Promise<void>}
     */
    async checkPurchase(id) {
      this.loading = true
      this.checkID = id
      let currentPackage = this.purchaseList.find(item => item.id === id)
      this.price = currentPackage.price
      this.discountPrice = currentPackage.discountPrice
      let res = await createOrder(id)
      if (res.data.status === 0) {
        let src = await this.toQRCode(res.data.data)
        this.qrCodeSrc = src
        this.loading = false
        this.pollCount = 0 // 清空轮询次数
        let s = res.data.data.split('/')
        let orderId = s[s.length - 1]
        this.lookup(orderId)
      } else {
        // 二维码获取失败
      }
    },
    /**
     * @description 关闭窗口
     */
    handleClose() {
      clearInterval(this.payResultTimer)
      this.hidePurchaseModal()
    },
    hidePurchaseModal() {
      this.visible = false
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
      this.$destroy()
    },

    /**
     * @description 打开窗口
     */
    onOpenDialog() {
      this.getPurchase()
    },
    handlePrev() {
      this.clickNumber++
    },
    handleNext() {
      this.clickNumber--
    }
  }
}
</script>
<style scoped lang="less">
@import './main.less';
</style>
